
import Vue from 'vue'
import { DIS_PERSONALLY_NUM, FILE_SPLIT } from '@/utils/contants'

export const formatUrl = (u) => {
  if ((/^(http)s?:\/\//.test(u))) return u
  return `${window._CONFIG.staticDomainURL}/${u}`
}
export const formatPriceList = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')) {
    return ''
  }
  if (!Vue.ls.get('isInner')) {
    return ''
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return '价格面议'
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      return `￥${formatIntegerPrice(item.price + item.postage)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}~${formatIntegerPrice(item.highestPrice + item.postage)}`
    }
  } else {
    return '价格面议'
  }
}
export const formatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')) {
    return '价格：请联系我们'
  }
  if (!Vue.ls.get('isInner')) {
    return '价格：请联系我们'
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return '价格面议'
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      return `￥${formatIntegerPrice(item.price + item.postage)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}~${formatIntegerPrice(item.highestPrice + item.postage)}`
    }
  } else {
    return '价格面议'
  }
}

export const HomeCountryformatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')||!Vue.ls.get('isInner')) {
    return ''
  }
  if (item.price && item.price >= DIS_PERSONALLY_NUM || item.lowestPrice >= DIS_PERSONALLY_NUM) {
    return ''
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为100亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.priceForHomeCountry + item.postageForHomeCountry)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最大价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}~${formatIntegerPrice(item.highestPriceForHomeCountry + item.postageForHomeCountry)}`
    }
  } else {
    return ''
  }
}

export const trimSecond = (date) => {
  date = date || ''
  const _idx = date.lastIndexOf(':')
  return date.slice(0, _idx)
}

export const getFileName = path => {
  if (!path) return ''
  if (path.lastIndexOf('\\') >= 0) {
    let reg = new RegExp('\\\\', 'g')
    path = path.replace(reg, '/')
  }
  let name = ''
  try {
    name = decodeURIComponent(path.substring(path.lastIndexOf('/') + 1))
  } catch (e) {
    name = path.substring(path.lastIndexOf('/') + 1)
  }
  let viewName = name.split(FILE_SPLIT)
  let suffix = ''

  if (viewName.length === 1) return viewName[0]
  if (viewName.length === 2) {
    // 唯一标识在前 情况下
    if (name.indexOf('vc-upload-') === 0) {
      suffix = viewName[viewName.length - 1]
    } else {
      suffix = `${viewName[0]}.${viewName[1].split('.')[1]}`
    }
  } else {
    let i = viewName.findIndex(item => !/^(vc-upload-).*/.test(item))
    // 如果截取出来的字段 不带唯一标识，并且为最后一个字段，则直接返回
    if (i === viewName.length - 1) {
      suffix = viewName[i]
    } else {
      suffix = `${viewName[i]}.${viewName[viewName.length - 1].split('.')[1]}`
    }
  }
  return suffix
}
Vue.filter('formatUrl', formatUrl)
Vue.filter('formatPrice', formatPrice)
Vue.filter('formatPriceList', formatPriceList)

Vue.filter('trimSecond', trimSecond)
Vue.filter('HomeCountryformatPrice', HomeCountryformatPrice)
Vue.filter('getFileName', getFileName)
